import React from 'react';
import { Box, SxProps } from '@mui/material';
import { icons } from 'lucide-react';

import { center } from '@/utils/sx';

export type IconName = keyof typeof icons;

type Props = {
	name: IconName;
	sx?: SxProps;
};

export const Icon = ({ name, sx }: Props) => {
	const LucideIcon = icons[name];

	return (
		<Box sx={{ ...center, display: 'inline-flex', ...(sx ?? {}) }}>
			<LucideIcon height="1em" />
		</Box>
	);
};
