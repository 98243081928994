import React, { useState } from 'react';
import { Button } from '@mui/material';
import { ArrowRight } from 'lucide-react';

import { BankAccountsList } from '@/molecules/bank-accounts/bank-accounts-list';
import { ConnectBankAccount } from '@/molecules/bank-accounts/connect-bank-account';
import { trpc } from '@/trpc/client';

export const BankAccountsManagement = () => {
	const [connectVisible, setConnectVisible] = useState(false);

	const { data } = trpc.bankAccounts.list.useQuery();

	return (
		<>
			<BankAccountsList data={data} />

			{Boolean((data && data.length === 0) || (data?.length && connectVisible)) && <ConnectBankAccount />}

			{Boolean(data?.length && !connectVisible) && (
				<Button
					variant="contained"
					startIcon={<ArrowRight />}
					sx={{}}
					onClick={() => {
						setConnectVisible(true);
					}}
				>
					Add another bank account
				</Button>
			)}
		</>
	);
};
