import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import { LoginButton } from '@/atoms/login-button';
import { Logo } from '@/atoms/logo';

import { useAuth } from '../providers/auth-provider';

export const Login = () => {
	const { triggerGoogleLogin } = useAuth();

	return (
		<Box sx={{ color: 'white' }}>
			<Box sx={{ height: 130, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
				<Logo />
			</Box>

			<Box
				sx={{
					mx: 'auto',
					mt: 16,
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography variant="h1" sx={{ fontSize: '4.5rem', fontWeight: 350 }}>
					Let&apos;s get you started
				</Typography>

				<Typography variant="body1" sx={{ mt: 4, mb: 8, maxWidth: 550, color: 'grey.300' }}>
					Streamline your business operations with our all-in-one subscription management platform, tailored
					specifically for companies.
				</Typography>

				<LoginButton onClick={triggerGoogleLogin}>Sign in with Google</LoginButton>
			</Box>
		</Box>
	);
};
