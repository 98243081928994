import React, { ReactNode, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';

import { config } from '@/utils/config';

import { getAuthToken } from '../utils/firebase';
import { trpc } from './client';

type Props = {
	children?: ReactNode;
};

export const TrpcProvider = ({ children }: Props) => {
	const [queryClient] = useState(() => new QueryClient());
	const [trpcClient] = useState(() =>
		trpc.createClient({
			links: [
				httpBatchLink({
					url: `${config.API_BASE_PATH}/trpc`,
					async headers() {
						const headers: Record<string, string> = {};

						const token = await getAuthToken();
						if (token) {
							headers['Authorization'] = `Bearer ${token}`;
							headers['X-Organization'] = sessionStorage.getItem('organizationId');
						}

						return headers;
					},
				}),
			],
		}),
	);

	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</trpc.Provider>
	);
};
