import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const LoginLayout = () => {
	return (
		<Box sx={{ bgcolor: 'grey.900', minHeight: '100vh' }}>
			<Container>
				<Outlet />
			</Container>
		</Box>
	);
};
