import React from 'react';
import { Skeleton, Typography } from '@mui/material';

import { Back } from '@/atoms/back';
import { Link } from '@/atoms/link-component';
import { SimpleTable } from '@/molecules/simple-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';

export const BackofficeOrganizations = () => {
	const { data, isLoading } = trpc.backoffice.organizations.list.useQuery();

	return (
		<>
			<Back to={links.backoffice.ROOT} />
			<Typography variant="h2" sx={{ mb: 2 }}>
				Organizations
			</Typography>

			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{data && (
				<SimpleTable
					rows={[
						['Name', <></>],
						...data.map(({ id, name }) => {
							return [<Link to={`${links.backoffice.organizations.ROOT}/${id}`}>{name}</Link>, <></>];
						}),
					]}
					firstLineBold
				/>
			)}
		</>
	);
};
