import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { DashboardLayout } from './layouts/dashboard-layout';
import { LoginLayout } from './layouts/login-layout';
import { OnboardingLayout } from './layouts/onboarding-layout';
import { BackofficeHomepage } from './pages/backoffice/backoffice-homepage';
import { BackofficeOrganizations } from './pages/backoffice/organizations/backoffice-organizations';
import { BackofficeOrganizationsDetail } from './pages/backoffice/organizations/backoffice-organizations-detail';
import { BackofficeOrganizationsInvoices } from './pages/backoffice/organizations/backoffice-organizations-invoices';
import { BackofficeOrganizationsMailbox } from './pages/backoffice/organizations/backoffice-organizations-mailbox';
import { BackofficeOrganizationsTransactions } from './pages/backoffice/organizations/backoffice-organizations-transactions';
import { BackofficeUsers } from './pages/backoffice/users/backoffice-users';
import { Dashboard } from './pages/dashboard';
import { GoCardlessCallback } from './pages/go-cardless-callback';
import { Login } from './pages/login';
import { NotFound } from './pages/not-found';
import { OnboardingBankAccounts } from './pages/onboarding/onboarding-bank-accounts';
import { OnboardingMailboxes } from './pages/onboarding/onboarding-mailboxes';
import { OnboardingOrganization } from './pages/onboarding/onboarding-organization';
import { OnboardingSuccess } from './pages/onboarding/onboarding-success';
import { OrganizationSettings } from './pages/organization-settings';
import { Profile } from './pages/profile';
import { links, paths } from './paths';
import { ProtectedRoutes, PublicOnlyRoutes } from './utils/protected-routes';

const routes: RouteObject[] = [
	{
		element: <ProtectedRoutes />,
		children: [
			{
				element: <DashboardLayout />,
				children: [
					{ path: paths.dashboard, element: <Dashboard /> },
					{ path: paths.subscriptions, element: null },
					{ path: paths.invoices, element: null },
					{ path: paths.profile, element: <Profile /> },
					{ path: paths.billing, element: null },
					{ path: paths.settings, element: <OrganizationSettings /> },
				],
			},
		],
	},
	{
		element: <ProtectedRoutes onboardingRequired={false} />,
		path: paths.onboarding.ROOT,
		children: [
			{
				element: <OnboardingLayout activeStep={1} />,
				children: [{ path: paths.onboarding.organization, element: <OnboardingOrganization /> }],
			},
			{
				element: <OnboardingLayout activeStep={2} />,
				children: [{ path: paths.onboarding.mailboxes, element: <OnboardingMailboxes /> }],
			},
			{
				element: <OnboardingLayout activeStep={3} />,
				children: [{ path: paths.onboarding.bankAccounts, element: <OnboardingBankAccounts /> }],
			},
			{
				element: <OnboardingLayout activeStep={4} />,
				children: [{ path: paths.onboarding.success, element: <OnboardingSuccess /> }],
			},
			{
				path: '',
				element: <Navigate to={links.onboarding.organization} />,
			},
		],
	},
	{
		element: <ProtectedRoutes />,
		path: paths.backoffice.ROOT,
		children: [
			{
				element: <DashboardLayout />,
				children: [
					{
						path: paths.backoffice.organizations.ROOT,
						children: [
							{
								path: paths.backoffice.organizations.detail.ROOT,
								children: [
									{ element: <BackofficeOrganizationsDetail />, index: true },
									{
										path: paths.backoffice.organizations.detail.mailbox,
										element: <BackofficeOrganizationsMailbox />,
									},
									{
										path: paths.backoffice.organizations.detail.transactions,
										element: <BackofficeOrganizationsTransactions />,
									},
									{
										path: paths.backoffice.organizations.detail.invoices,
										element: <BackofficeOrganizationsInvoices />,
									},
								],
							},
							{ index: true, element: <BackofficeOrganizations /> },
						],
					},
					{
						path: paths.backoffice.users.ROOT,
						children: [{ index: true, element: <BackofficeUsers /> }],
					},
					{ index: true, element: <BackofficeHomepage /> },
				],
			},
		],
	},
	{
		element: <PublicOnlyRoutes />,
		children: [
			{
				element: <LoginLayout />,
				children: [{ path: paths.login, element: <Login /> }],
			},
		],
	},
	{
		path: paths.notFound,
		element: <LoginLayout />,
		children: [{ path: '', element: <NotFound /> }],
	},
	{
		path: '/gocardless/callback',
		element: <GoCardlessCallback />,
	},
	{
		path: '*',
		element: <Navigate to={links.notFound} />,
	},
];

export const Routes = () => useRoutes(routes);
