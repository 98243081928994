import React from 'react';
import { Box, SxProps } from '@mui/material';

type Props = {
	country: string;
	sx?: SxProps;
};

export const Flag = ({ country, sx = {} }: Props) => {
	const flagUrl = `https://flagcdn.com/w40/${country.toLowerCase()}.png`;

	return (
		<Box
			sx={{
				width: 24,
				height: 18,
				backgroundImage: `url("${flagUrl}")`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				marginRight: 1,
				...sx,
			}}
		/>
	);
};
