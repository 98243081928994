import React from 'react';
import { Box, Button, ButtonProps, styled } from '@mui/material';
import { ArrowRight } from 'lucide-react';

import { Google } from './google';

const GoogleLoginButton = styled(Button)<ButtonProps>(({ theme, size }) => ({
	'color': theme.palette.grey['900'],
	'backgroundColor': 'white',
	'borderRadius': '1.6875rem',
	'display': 'flex',
	'alignItems': 'center',
	'border': `solid 1px ${theme.palette.grey['200']}`,

	'paddingLeft': '1rem',
	'paddingRight': '1rem',

	'&:disabled': {
		opacity: 0.8,
		backgroundColor: 'white',
	},
	'&:hover': {
		opacity: 0.8,
		backgroundColor: 'white',
	},

	'& svg:first-of-type': {
		fontSize: '1.25rem',
	},
}));

export const LoginButton = ({ children, ...props }: ButtonProps) => {
	return (
		<GoogleLoginButton {...props}>
			<Google />

			<Box sx={{ ml: 1.5, mr: 1.5 }} component="span">
				{children}
			</Box>

			<ArrowRight />
		</GoogleLoginButton>
	);
};
