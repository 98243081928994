export const paths = {
	dashboard: '',
	subscriptions: 'subscriptions',
	invoices: 'invoices',
	profile: 'profile',
	billing: 'billing',
	settings: 'settings',
	onboarding: {
		ROOT: 'onboarding',
		organization: 'organization',
		bankAccounts: 'bank-accounts',
		mailboxes: 'mailboxes',
		success: 'success',
	},
	login: 'login',
	notFound: 'not-found',
	backoffice: {
		ROOT: 'backoffice',
		organizations: {
			ROOT: 'organizations',
			detail: {
				ROOT: ':id',
				transactions: 'transactions',
				mailbox: 'mailbox',
				invoices: 'invoices',
			},
		},
		users: {
			ROOT: 'users',
		},
	},
};

const linksProxyHandler: (prefix: string) => ProxyHandler<typeof paths> = (prefix) => ({
	get(target, p) {
		// eslint-disable-next-line
		const value = Reflect.get(target, p);

		if (typeof value === 'object' && 'ROOT' in value) {
			// eslint-disable-next-line
			return new Proxy(value, linksProxyHandler(`${prefix}${value.ROOT}${value.ROOT ? '/' : ''}`));
		}

		if (p === 'ROOT') {
			if (prefix.length === 1) return prefix;
			return prefix.slice(0, prefix.length - 1);
		}

		return typeof value === 'string' && `${prefix}${value}`;
	},
});

export const links = new Proxy(paths, linksProxyHandler('/'));
