import React from 'react';
import { Alert, Skeleton, Typography } from '@mui/material';

import { SimpleTable } from '@/molecules/simple-table';
import { trpc } from '@/trpc/client';

type Props = {
	organizationId: string;
};
export const BackofficeOrganizationsMembers = ({ organizationId }: Props) => {
	const { data, isLoading, isError } = trpc.backoffice.organizations.listMembers.useQuery({ organizationId });

	return (
		<>
			<Typography variant="h3">Members</Typography>

			{isError && <Alert severity="error">There was an error</Alert>}

			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{data && (
				<SimpleTable
					rows={[
						['Name', 'Email', ''],
						...data.map(({ displayName, email }) => {
							return [displayName, email, <></>];
						}),
					]}
					firstLineBold
				/>
			)}
		</>
	);
};
