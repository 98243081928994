import React from 'react';
import { Skeleton, Typography } from '@mui/material';

import { Back } from '@/atoms/back';
import { SimpleTable } from '@/molecules/simple-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';

export const BackofficeUsers = () => {
	const { data, isLoading } = trpc.backoffice.users.list.useQuery();

	return (
		<>
			<Back to={links.backoffice.ROOT} />
			<Typography variant="h2" sx={{ mb: 2 }}>
				Users
			</Typography>

			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{data && (
				<SimpleTable
					rows={[
						['Name', 'Email', <></>],
						...data.map(({ displayName, email }) => {
							return [displayName, email, <></>];
						}),
					]}
					firstLineBold
				/>
			)}
		</>
	);
};
