import React, { useEffect } from 'react';
import { Alert, CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { links } from '@/paths';
import { trpc } from '@/trpc/client';

export const GoCardlessCallback = () => {
	const navigate = useNavigate();
	const [search] = useSearchParams();

	const { mutate, isLoading, isError } = trpc.bankAccounts.fetchRequisitions.useMutation({
		onSuccess() {
			navigate(links.onboarding.bankAccounts, { replace: false });
		},
		onError() {
			alert('There was an error');
		},
	});

	useEffect(() => {
		const ref = search.get('ref');
		if (typeof ref !== 'string') return;
		mutate({ requisitionId: ref });
	}, [search]);

	return (
		<div>
			{isLoading && <CircularProgress sx={{ mx: 'auto', display: 'block' }} />}
			{isError && <Alert severity="error">There was an error when handling GoCardless callback</Alert>}
		</div>
	);
};
