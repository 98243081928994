import React, { ReactNode } from 'react';
import { Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { notNullish } from '@/utils/utils';

type Props = {
	rows: (ReactNode[] | null)[];
	sx?: SxProps;
	elevation?: number;
	firstColBold?: boolean;
	lastColBold?: boolean;
	firstLineBold?: boolean;
};

export const SimpleTable = ({ rows, elevation, firstColBold, lastColBold, firstLineBold, sx = {} }: Props) => {
	return (
		<TableContainer component={Paper} elevation={elevation || 0} sx={{ py: 2, ...sx }}>
			<Table size="small">
				<TableBody>
					{rows.filter(notNullish).map((cells, i) => (
						<TableRow key={i} sx={firstLineBold && i === 0 ? { '& td': { fontWeight: 700 } } : {}}>
							{cells.map((content, ii) => {
								const sx =
									ii === cells.length - 1
										? {
												textAlign: 'right',
												fontWeight: lastColBold ? 700 : 400,
											}
										: ii === 0
											? { fontWeight: firstColBold ? 700 : 400 }
											: {};

								return (
									<TableCell key={ii} sx={sx}>
										{content}
									</TableCell>
								);
							})}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
