import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
	apiKey: process.env.FIREBASE_API_KEY,
	authDomain: process.env.FIREBASE_AUTH_DOMAIN,
	projectId: process.env.FIREBASE_PROJECT_ID,
	storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.FIREBASE_APP_ID,
	measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAppMailbox = initializeApp(firebaseConfig, 'mailbox');

export const firebaseAuth = getAuth(firebaseApp);
export const firebaseAuthMailbox = getAuth(firebaseAppMailbox);

if (process.env.FIREBASE_LOCAL_AUTH_EMULATOR) {
	connectAuthEmulator(firebaseAuth, 'http://127.0.0.1:9099');
	connectAuthEmulator(firebaseAuthMailbox, 'http://127.0.0.1:9099');
}

export const googleAuthProvider = new GoogleAuthProvider();

export function getGoogleAuthProviderMailbox({ loginHint }: { loginHint?: string } = {}) {
	const provider = new GoogleAuthProvider();

	provider.addScope('https://www.googleapis.com/auth/gmail.readonly');

	const parameter: Record<string, string> = {
		access_type: 'offline',
		prompt: 'consent',
	};
	if (loginHint) parameter.login_hint = loginHint;
	provider.setCustomParameters(parameter);

	return provider;
}

googleAuthProvider.addScope('https://www.googleapis.com/auth/gmail.readonly');
googleAuthProvider.setCustomParameters({
	access_type: 'offline',
	prompt: 'consent',
});

export async function getAuthToken() {
	return await firebaseAuth.currentUser?.getIdToken();
}
