import React, { MouseEvent, ReactNode } from 'react';
import { Avatar, Box, ClickAwayListener, Popper, Stack, Typography } from '@mui/material';
import { To } from 'react-router-dom';

import { Icon, IconName } from '@/atoms/icon';
import { Link } from '@/atoms/link-component';
import { links } from '@/paths';
import { border } from '@/utils/sx';

import { useAuth } from '../../providers/auth-provider';

const menuBgColor = '#313536';
const menuActiveBgColor = '#494F56';

export const UserMenu = () => {
	const { user, logout, me } = useAuth();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const userMenuItems: {
		label: ReactNode;
		to?: To;
		iconName: IconName;
		onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
	}[] = [
		{ label: 'Profile', to: links.profile, iconName: 'User' },
		{ label: 'Billing', to: links.billing, iconName: 'CreditCard' },
		{ label: 'Settings', to: links.settings, iconName: 'Settings' },
		{
			label: 'Log-out',
			onClick: (e) => {
				e.preventDefault();
				logout();
			},
			iconName: 'LogOut',
		},
	];

	return (
		<>
			<Stack
				sx={{
					'ml': 'auto',
					'p': 1.25,
					'bgcolor': open ? menuActiveBgColor : menuBgColor,
					'borderRadius': 8,
					'alignItems': 'center',
					'cursor': 'pointer',
					'width': 215,
					...border('grey.700'),
					'&:hover': { bgcolor: menuActiveBgColor },
				}}
				direction="row"
				gap={1.25}
				onClick={handleClick}
			>
				<Avatar src={user?.photoURL ?? ''} sx={{ width: 40, height: 40 }} />

				<Stack sx={{ mr: 2 }}>
					<Typography variant="input">{user?.displayName}</Typography>
					<Typography variant="body3" sx={{ color: 'grey.400' }}>
						{me?.organizations[0]?.name ?? ''}
					</Typography>
				</Stack>

				<Icon name={open ? 'ChevronUp' : 'ChevronDown'} sx={{ ml: 'auto' }} />
			</Stack>

			<Popper open={open} anchorEl={anchorEl} sx={{ width: 215 }}>
				<ClickAwayListener onClickAway={handleClose}>
					<Box
						sx={{
							border: 1,
							mt: 1,
							px: 1.25,
							pt: 1,
							pb: 1.5,
							bgcolor: menuActiveBgColor,
							...border('grey.700'),
							borderRadius: '20px',
						}}
					>
						<Typography variant="body3" component="p" sx={{ color: 'grey.400', px: 0.75, py: 1 }}>
							My Account
						</Typography>

						<Stack>
							{userMenuItems.map(({ label, to, iconName, onClick }) => {
								return (
									<Link
										key={label}
										to={to}
										onClick={onClick}
										sx={{
											'display': 'grid',
											'gridTemplateColumns': '1rem 1fr',
											'color': 'white',
											'gap': 1,
											'px': 1,
											'py': 0.5,
											'borderRadius': 1,
											'&:hover': ({ palette }) => ({
												bgcolor: `${palette.primary['100']}1A`,
											}),
										}}
									>
										<Icon name={iconName} />
										<span>{label}</span>
									</Link>
								);
							})}
						</Stack>
					</Box>
				</ClickAwayListener>
			</Popper>
		</>
	);
};
