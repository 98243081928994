import React from 'react';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { FormattedNumber } from 'react-intl';

import { Card } from '@/atoms/card';
import { useAuth } from '@/providers/auth-provider';
import { trpc } from '@/trpc/client';

type Props = {
	from: string;
	to: string;
};

export const Overview = ({ from, to }: Props) => {
	const { me } = useAuth();

	const { data } = trpc.overview.useQuery({ from, to }, { refetchInterval: 5_000 });

	if (!data) {
		return null;
	}

	return (
		<>
			<Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
				<Card
					title={<>Total spend</>}
					value={
						<FormattedNumber
							value={data.totalAmount / 100}
							style="currency"
							currency={me.organizations[0]?.currency}
							maximumFractionDigits={0}
						/>
					}
				/>

				<Card title={<>Total subscriptions</>} value={data.invoicesCount} />
			</Box>

			<Divider sx={{ mt: 6, mb: 3 }} />
		</>
	);
};
