import React from 'react';
import { Typography } from '@mui/material';

import { BankAccountsManagement } from '@/organisms/bank-accounts-management';

export const BankAccounts = () => {
	return (
		<>
			<Typography variant="h3" sx={{ mt: 2 }}>
				Bank Accounts
			</Typography>

			<BankAccountsManagement />
		</>
	);
};
