import React from 'react';
import { Alert, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { usePagination } from '@/utils/use-pagination';

import { RouterOutput } from '../../../../../functions/src/trpc/app-router';

type Item = RouterOutput['backoffice']['organizations']['mailbox']['list']['data'][number];

const columns: GridColDef<Item>[] = [
	{ field: 'from', headerName: 'From', flex: 1 },
	{ field: 'subject', headerName: 'Subject', flex: 1 },
	{
		field: 'receivedAt',
		headerName: 'Received At',
		flex: 1,
		renderCell({ value }) {
			return <FormattedDatetime value={value} />;
		},
	},
	{
		field: 'attachments',
		headerName: 'Attachments',
		flex: 1,
		renderCell({ value }) {
			return <>{value && value.join(', ')}</>;
		},
	},
	{ field: 'mailbox', headerName: 'Mailbox', flex: 1 },
];

export const BackofficeOrganizationsMailbox = () => {
	const { id } = useParams<{ id: string }>();
	if (!id) throw new Error('No organization id');

	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({ id });
	const { data, isError, isLoading } = trpc.backoffice.organizations.mailbox.list.useQuery({
		organizationId: id,
		page,
		pageSize,
	});

	return (
		<>
			<Back to={links.backoffice.organizations.detail.ROOT.replace(':id', id)} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Mailbox of {organization?.name ?? ''}
			</Typography>

			{(organizationIsError || isError) && <Alert severity="error">There was an error</Alert>}

			<DataGrid
				rows={data?.data ?? []}
				loading={isLoading}
				columns={columns}
				density="compact"
				rowCount={data?.pagination.count ?? 0}
				paginationModel={paginationModel}
				paginationMode="server"
				onPaginationModelChange={onPaginationModelChange}
			/>
		</>
	);
};
