import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { formatISO } from 'date-fns/formatISO';

import { DatePickerWithRange, defaultValue } from '@/atoms/date-picker-with-range';
import { Overview } from '@/templates/overview';
import { Subscriptions } from '@/templates/subscriptions';

export const Dashboard = () => {
	const [dateRange, setDateRange] = useState(defaultValue);

	const { from, to } = useMemo(() => {
		return {
			from: formatISO(dateRange.from, { representation: 'date' }),
			to: formatISO(dateRange.to, { representation: 'date' }),
		};
	}, [dateRange]);

	return (
		<>
			<Box sx={{ mb: 2 }}>
				<Typography variant="h2">Overview</Typography>

				<DatePickerWithRange value={dateRange} onSelect={setDateRange} />
			</Box>

			<Overview from={from} to={to} />

			<Subscriptions from={from} to={to} />

			<Box sx={{ mt: 5 }} />
		</>
	);
};
