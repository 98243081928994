import React, { ReactNode, useState } from 'react';
import { add, endOfMonth, startOfMonth } from 'date-fns';
import { FormattedDate } from 'react-intl';

const options: { key: string; label: ReactNode; range: DateRange }[] = [
	{
		key: 'this-month',
		label: 'This month',
		range: { from: startOfMonth(new Date()), to: endOfMonth(new Date()) },
	},
	{
		key: 'prev-month',
		label: 'Previous month',
		range: { from: startOfMonth(add(new Date(), { months: -1 })), to: endOfMonth(add(new Date(), { months: -1 })) },
	},
	{
		key: 'last-3-months',
		label: 'Last 3 months',
		range: { from: startOfMonth(add(new Date(), { months: -3 })), to: endOfMonth(new Date()) },
	},
	{
		key: 'last-6-months',
		label: 'Last 6 months',
		range: { from: startOfMonth(add(new Date(), { months: -6 })), to: endOfMonth(new Date()) },
	},
];

type DateRange = {
	from: Date;
	to: Date;
};

export const defaultValue: DateRange = {
	from: startOfMonth(add(new Date(), { months: -3 })),
	to: endOfMonth(new Date()),
};

type Props = {
	value: DateRange;
	className?: string;
	onSelect: (value: DateRange) => void;
};

export function DatePickerWithRange({ className, value: date, onSelect }: Props) {
	const [option, setOption] = useState<string | undefined>('last-3-months');

	return null;
}
