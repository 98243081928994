import { useState } from 'react';
import { getAdditionalUserInfo, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { trpc } from '@/trpc/client';

import { firebaseAuthMailbox, getGoogleAuthProviderMailbox } from './firebase';

export const useConnectMailbox = () => {
	const [isLoading, setLoading] = useState(false);

	const { mutate } = trpc.mailboxConnect.useMutation({ onSettled: () => setLoading(false) });

	const connectMailbox = async ({ loginHint }: { loginHint?: string } = {}) => {
		setLoading(true);

		signInWithPopup(firebaseAuthMailbox, getGoogleAuthProviderMailbox({ loginHint })).then((result) => {
			const credential = GoogleAuthProvider.credentialFromResult(result);
			const user = result.user;
			const { profile } = getAdditionalUserInfo(result);

			mutate({
				userId: user.uid,
				email: user.email,
				// @ts-ignore
				gmailUserId: profile.id,
			});
		});
	};

	return { connectMailbox, isLoading };
};
