import React from 'react';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';

import './utils/firebase';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './providers/auth-provider';
import { Routes } from './router';
import { TrpcProvider } from './trpc/provider';
import { theme } from './utils/theme';

import './styles/globals.css';

export const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<TrpcProvider>
				<AuthProvider>
					<IntlProvider locale="en">
						<SnackbarProvider>
							<ConfirmProvider>
								<BrowserRouter>
									<Routes />
								</BrowserRouter>
							</ConfirmProvider>
						</SnackbarProvider>
					</IntlProvider>
				</AuthProvider>
			</TrpcProvider>
		</ThemeProvider>
	);
};
