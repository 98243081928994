import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { getAdditionalUserInfo, GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';

import { trpc } from '@/trpc/client';

import { RouterOutput } from '../../../functions/src/trpc/app-router';
import { firebaseAuth, googleAuthProvider } from '../utils/firebase';

type Props = {
	children?: ReactNode;
};

type Me = RouterOutput['me'];

type AuthContextType = {
	isAuthenticated: boolean;
	triggerGoogleLogin: () => void;
	logout?: () => void;
	user: User | null;
	token: string | null;
	userId: string | null;
	me: Me | null;
};

const AuthContext = createContext<AuthContextType>({
	isAuthenticated: false,
	triggerGoogleLogin: () => {
		throw new Error('triggerGoogleLogin not implemented');
	},
	logout: () => {
		throw new Error('logout not implemented');
	},
	user: null,
	token: null,
	userId: null,
	me: null,
});

export const AuthProvider = ({ children }: Props) => {
	const [initialized, setInitialized] = useState(false);
	const [user, setUser] = useState<User | null>(null);
	const [token, setToken] = useState<string | null>(null);
	const [userId, setUserId] = useState<string | null>(null);

	const isAuthenticated = Boolean(user);

	useEffect(() => {
		const unregister = firebaseAuth.onAuthStateChanged(
			(u) => {
				setInitialized(true);
				setUser(u);
			},
			(err) => console.error(err),
		);

		return unregister;
	}, []);

	const triggerGoogleLogin = () => {
		signInWithPopup(firebaseAuth, googleAuthProvider)
			.then((result) => {
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential?.accessToken;
				const user = result.user;
				const x = getAdditionalUserInfo(result);

				const userId = x?.profile?.id as string;

				setToken(token ?? null);
				setUserId(userId ?? null);
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.customData.email;
				// The AuthCredential type that was used.
				const credential = GoogleAuthProvider.credentialFromError(error);
				console.error({
					errorCode,
					errorMessage,
					email,
					credential,
				});
			});
	};

	const me = trpc.me.useQuery(undefined, { enabled: Boolean(user) });

	useEffect(() => {
		sessionStorage.setItem('organizationId', me.data?.organizations?.[0]?.id);
	}, [me.data]);

	const logout = () => {
		firebaseAuth.signOut();
	};

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				triggerGoogleLogin,
				logout,
				user,
				token,
				userId,
				me: me.data,
			}}
		>
			{!initialized || me.isInitialLoading ? (
				<CircularProgress sx={{ display: 'block', mx: 'auto', mt: 6 }} />
			) : (
				children
			)}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
