import React from 'react';
import { Stack, Typography } from '@mui/material';

import { Link } from '@/atoms/link-component';
import { links } from '@/paths';

export const BackofficeHomepage = () => {
	return (
		<>
			<Typography variant="h2" sx={{ mb: 2 }}>
				Backoffice
			</Typography>
			<Stack gap={0.5}>
				<Link to={links.backoffice.organizations.ROOT}>Organizations</Link>
				<Link to={links.backoffice.users.ROOT}>Users</Link>
			</Stack>
		</>
	);
};
