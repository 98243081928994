import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { CountrySelect } from '@/atoms/bank-accounts/country-select';
import { InstitutionSelect } from '@/atoms/bank-accounts/institution-select';
import { trpc } from '@/trpc/client';

type Props = {};

export const ConnectBankAccount = ({}: Props) => {
	const [country, setCountry] = useState('CZ');
	const [institutionId, setInstitutionId] = useState<string | undefined>(undefined);

	useEffect(() => {
		setInstitutionId(undefined);
	}, [country]);

	const { mutate } = trpc.bankAccounts.createRequisition.useMutation({
		onSuccess(res) {
			if (res.link) {
				window.location.href = res.link;
			}
		},
	});

	return (
		<>
			<CountrySelect value={country} onChange={setCountry} />

			<InstitutionSelect country={country} institutionId={institutionId} setInstitutionId={setInstitutionId} />

			<Button
				disabled={!institutionId}
				onClick={() => {
					if (institutionId) mutate({ institutionId });
				}}
			>
				Connect
			</Button>
		</>
	);
};
