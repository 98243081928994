import React from 'react';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';

import { trpc } from '@/trpc/client';

type Props = {
	country: string;
	institutionId: string | undefined;
	setInstitutionId: (institutionId: string | undefined) => void;
};

export const InstitutionSelect = ({ country, institutionId, setInstitutionId }: Props) => {
	const { isError, isLoading, data: institutions } = trpc.bankAccounts.listInstitutions.useQuery({ country });

	const selectedInstitution = (institutions ?? []).find(({ id }) => id === institutionId) ?? null;

	return (
		<Autocomplete
			disabled={isLoading || isError}
			disablePortal
			disableClearable
			options={institutions ?? []}
			value={selectedInstitution}
			onChange={(_, value) => {
				setInstitutionId((value as any).id);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Select a bank"
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								<InstitutionLogo logo={selectedInstitution?.logo} />
							</InputAdornment>
						),
					}}
				/>
			)}
			getOptionLabel={(option) => option.name}
			renderOption={(props, option) => {
				return (
					<Box key={option.id} component="li" {...props}>
						<InstitutionLogo logo={option.logo} />
						{option.name}
					</Box>
				);
			}}
		/>
	);
};

const InstitutionLogo = ({ logo }: { logo?: string }) => {
	return (
		<Box
			sx={{
				width: 24,
				height: 24,
				borderRadius: '50%',
				backgroundImage: logo ? `url("${logo}")` : 'none',
				backgroundSize: 'cover',
				backPosition: 'center',
				mr: 1,
			}}
		/>
	);
};
