import React from 'react';
import { Box, Typography } from '@mui/material';

import { Link } from '@/atoms/link-component';
import { Logo } from '@/atoms/logo';
import { links } from '@/paths';

export const NotFound = () => {
	return (
		<Box sx={{ color: 'white' }}>
			<Box sx={{ height: 130, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Logo />
			</Box>

			<Typography variant="h1" sx={{ textAlign: 'center' }}>
				Not Found
			</Typography>

			<Typography sx={{ textAlign: 'center', color: 'grey.300', mt: 2 }}>
				The page you are looking for does not exist.{' '}
				<Link
					to={links.login}
					sx={{ color: 'grey.300', borderBottom: (t) => `solid 1px ${t.palette.grey['300']}` }}
				>
					Go home
				</Link>
				.
			</Typography>
		</Box>
	);
};
