import React from 'react';
import { Box, Container, Paper, Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Outlet } from 'react-router-dom';

import { Logo } from '@/atoms/logo';

type Props = {
	activeStep?: number;
};

export const OnboardingLayout = ({ activeStep = 1 }: Props) => {
	return (
		<Box sx={{ bgcolor: 'grey.900', color: 'white', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
			<Grid container sx={{ p: 2.5, flex: 1 }}>
				<Grid xs={5} sx={{ pt: 5.5, pl: 7.5 }}>
					<Logo />

					<Stepper
						activeStep={activeStep}
						connector={<StepConnector />}
						orientation="vertical"
						sx={{ mt: 10 }}
					>
						{[
							{ label: 'Create an account with Magnet' },
							{ label: 'Create a space for your company' },
							{ label: 'Connect Magnet to all your inboxes' },
							{ label: 'Connect Magnet to your bank account' },
							{ label: 'Dashboard & licenses' },
						].map(({ label }) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Grid>
				<Grid xs={7} sx={{}}>
					<Paper sx={{ borderRadius: '40px', pt: 4, height: '100%' }} elevation={0}>
						<Container sx={{ height: '100%', display: 'flex', flexDirection: 'column', py: 4 }}>
							<Outlet />
						</Container>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
};
