import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
	title: ReactNode;
	value: ReactNode;
};

export const Card = ({ title, value }: Props) => {
	return (
		<Box
			sx={{
				p: 4,
				borderRadius: '20px',
				bgcolor: '#F2F6F6',
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: 'grey.200',
			}}
		>
			<Typography variant="h6">{title}</Typography>
			<Typography sx={{ fontSize: '4.5rem', color: 'text.primary', fontWeight: 500, lineHeight: 1, mt: 6 }}>
				{value}
			</Typography>
		</Box>
	);
};
