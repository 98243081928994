import React, { ComponentProps } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

type Props = {
	value: ComponentProps<typeof FormattedDate>['value'];
};

export const FormattedDatetime = ({ value }: Props) => {
	return (
		<>
			<FormattedDate value={value} /> <FormattedTime value={value} />
		</>
	);
};
