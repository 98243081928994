import React, { ReactNode } from 'react';
import {
	Box,
	Link,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
} from '@mui/material';
import { first, max } from 'lodash';
import { FormattedDate, FormattedNumber } from 'react-intl';

import { Icon } from '@/atoms/icon';
import { trpc } from '@/trpc/client';
import { config } from '@/utils/config';
import { usePagination } from '@/utils/use-pagination';

import { RouterOutput } from '../../../functions/src/trpc/app-router';

type Props = {
	search?: string;
	from: string;
	to: string;
};

export const InvoicesTable = ({ search, from, to }: Props) => {
	const { page, pageSize, onPageChange, onRowsPerPageChange } = usePagination();

	const { data } = trpc.listInvoices.useQuery({ from, to, search, page, pageSize }, { refetchInterval: 5_000 });

	if (!data) return null;

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Tool name</TableCell>
						<TableCell>Invoice</TableCell>
						<TableCell>Tag</TableCell>
						<TableCell>Amount</TableCell>
						<TableCell>Last billing</TableCell>
						<TableCell>Price</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.data.map(
						({
							id,
							supplierName,
							invoiceDate,
							totalAmount,
							currency,
							currencyOnInvoice,
							totalAmountOnInvoice,
							openaiResult,
						}) => {
							let amount: ReactNode = null;
							if (openaiResult) {
								const quantity = max(openaiResult.items.map((item) => item.quantity));
								const quantityUnit = first(openaiResult.items.map((item) => item.quantityUnit));

								if (quantityUnit === 'users') {
									amount = `${quantity}`;
								}
							}

							return (
								<TableRow key={id}>
									<TableCell sx={{ typography: 'body2', fontWeight: 700 }}>{supplierName}</TableCell>
									<TableCell>
										<Link
											href={`${config.API_BASE_PATH}/download/invoices/${id}`}
											target="_blank"
											sx={{ color: 'text.primary' }}
										>
											<Icon name="Download" />
											Download
										</Link>
									</TableCell>

									<TableCell />
									<TableCell>{amount}</TableCell>

									<TableCell>
										<FormattedDate value={invoiceDate} />
									</TableCell>

									<TableCell>
										{totalAmount && (
											<Stack>
												<Box>
													<FormattedNumber
														style="currency"
														value={Number(totalAmount) / 100}
														maximumFractionDigits={0}
														currency={currency}
													/>
												</Box>

												{totalAmountOnInvoice !== totalAmount && (
													<Box sx={{ opacity: 0.6 }}>
														<FormattedNumber
															style="currency"
															value={Number(totalAmountOnInvoice) / 100}
															maximumFractionDigits={0}
															currency={currencyOnInvoice}
														/>
													</Box>
												)}
											</Stack>
										)}
									</TableCell>
								</TableRow>
							);
						},
					)}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							count={data.pagination.count}
							page={page}
							rowsPerPage={pageSize}
							onPageChange={onPageChange}
							onRowsPerPageChange={onRowsPerPageChange}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</>
	);
};
