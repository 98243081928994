import React from 'react';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';

import { Flag } from '../flag';

type Props = {
	value: string;
	onChange: (country: string) => void;
};

const countries = [
	{ label: 'Czech Republic', value: 'CZ' },
	{ label: 'Denmark', value: 'DK' },
];

export const CountrySelect = ({ value: country, onChange }: Props) => {
	return (
		<Autocomplete
			disablePortal
			options={countries}
			sx={{ mb: 4 }}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Country"
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								<Flag country={country} />
							</InputAdornment>
						),
					}}
				/>
			)}
			disableClearable
			value={countries.find((c) => c.value === country)}
			onChange={(_, value) => {
				onChange(value.value);
			}}
			renderOption={(props, option) => {
				return (
					<Box key={option.value} component="li" {...props}>
						<Flag country={option.value} sx={{ mr: 1 }} />
						{option.label}
					</Box>
				);
			}}
		/>
	);
};
