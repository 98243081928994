import { createTheme, PaletteOptions } from '@mui/material';

export const eerie = {
	50: '#EFF3F3',
	100: '#ECF3F4',
	200: '#E0EBEC',
	300: '#C9D7D8',
	400: '#95ACB1',
	500: '#5B757B',
	600: '#45595E',
	700: '#364548',
	800: '#222B2D',
	900: '#1A1F20',
};

const powder = {
	50: '#FCFBF9',
	100: '#FBF9F6',
};

const majorela = {
	50: '#ECF3FF',
	100: '#DDE8FF',
	200: '#C2D4FF',
	300: '#9DB8FF',
	400: '#768FFF',
	500: '#495DFE',
	600: '#373DF4',
	700: '#2B2DD7',
	800: '#262B89',
	900: '#17194F',
};

const lime = {
	50: '#FBFFE4',
	100: '#F5FFC4',
	200: '#EAFF90',
	300: '#D8FF50',
	400: '#C9FF2E',
	500: '#A5E600',
	600: '#7FB800',
};

const aquamarine = {
	50: '#ECFDF6',
	100: '#D2F9E6',
	200: '#A9F1D3',
	300: '#65E2B5',
	400: '#38CF9E',
	500: '#14B586',
	600: '#08936D',
	700: '#07755A',
	800: '#085D48',
	900: '#084C3C',
};

export const palette = {
	primary: { main: majorela['500'], contrastText: '#fff', ...majorela },
	secondary: { main: lime['400'], contrastText: eerie['900'], ...lime },
	grey: { ...eerie },
	text: { primary: eerie['900'], disabled: eerie['300'] },
	action: { disabledBackground: eerie['100'], disabled: eerie['300'] },
} satisfies PaletteOptions;

export const theme = createTheme({
	typography: {
		fontFamily: 'uncut_sans, Arial, Helvetica, sans-serif',
		h1: {
			fontWeight: 500,
			fontSize: '3rem',
			lineHeight: 1.15,
		},
		h2: {
			fontWeight: 400,
			fontSize: '2.5rem',
			lineHeight: 1.4,
		},
		h3: {
			fontWeight: 400,
			fontSize: '2rem',
			lineHeight: 1.2,
		},
		h4: {
			fontWeight: 400,
			fontSize: '1.75rem',
			lineHeight: 1.2,
		},
		h5: {
			fontWeight: 400,
			fontSize: '1.5rem',
			lineHeight: 1.3,
		},
		h6: {
			fontWeight: 400,
			fontSize: '1.25rem',
			lineHeight: 1.4,
		},
		body1: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: 1.75,
			color: eerie['500'],
		},
		body2: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: 1.5,
		},
		body3: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: 1.33,
		},
		button: {
			fontWeight: 500,
			fontSize: '0.875rem',
			lineHeight: 1.4,
		},
		input: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: 1.4,
		},
		inputHighlights: {
			fontWeight: 500,
			fontSize: '0.875rem',
			lineHeight: 1.4,
		},
	},
	palette,
	components: {
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: eerie['900'],
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					'textTransform': 'none',
					'boxShadow': 'none !important',

					'&.Mui-disabled': {
						'& .MuiButton-icon': {
							color: 'white',
							backgroundColor: palette.grey['300'],
						},
					},
				},
				contained: {
					backgroundColor: palette.text.primary,
				},
				startIcon: {
					backgroundColor: palette.primary.main,
					borderRadius: '50%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginLeft: '-0.5rem',
				},
				iconSizeMedium: {
					width: '2.5rem',
					height: '2.5rem',
				},
				sizeMedium: {
					fontSize: '1rem',
					height: '3.375rem',
					borderRadius: '1.6875rem',
				},
				sizeSmall: {
					fontSize: '0.875rem',
					height: '2.75rem',
					borderRadius: '1.375rem',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: powder['50'],
				},
				outlined: {
					borderRadius: '20px',
					border: `solid 1px ${eerie['200']}`,
					backgroundColor: '#F2F6F6',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '28px',
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: `solid 0.5px ${eerie['300']}`,
					fontSize: '0.75rem',
				},
				head: {
					'color': eerie['800'],
					'fontSize': '0.75rem',
					'fontWeight': 400,
					'backgroundColor': eerie['50'],
					'border': 0,
					'&:first-child': {
						borderTopLeftRadius: '12px',
					},
					'&:last-child': {
						borderTopRightRadius: '12px',
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {},
				head: {},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				root: {
					padding: 0,
				},
				label: {
					'fontSize': '1rem',
					'paddingLeft': '2.5em',
					'color': 'white',
					'&.Mui-completed': {
						color: 'white',
					},
					'&.Mui-active': {
						color: 'white',
					},
				},
				iconContainer: {
					'padding': 0,
					'&.Mui-disabled': {
						'position': 'relative',
						'&:after': {
							content: '""',
							width: '100%',
							height: '100%',
							position: 'absolute',
							top: 0,
							left: 0,
							border: 'solid 1px white',
							borderRadius: '50%',
						},
					},
				},
			},
		},
		MuiStepConnector: {
			styleOverrides: {
				root: {
					'marginLeft': '20px',

					'& .MuiStepConnector-line': {
						borderColor: 'white',
					},
					'&.Mui-disabled .MuiStepConnector-line': {
						borderColor: 'rgba(255, 255, 255, 0.38)',
					},
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					'fontSize': '40px',
					'color': 'transparent',
					'&.Mui-completed': {
						color: aquamarine['500'],
					},
					'&.Mui-active': {
						'color': 'white',
						'& .MuiStepIcon-text': {
							fill: 'black',
						},
					},
				},
				text: {
					fill: 'white',
				},
			},
		},
	},
});

declare module '@mui/material/styles' {
	interface TypographyVariants {
		body3: React.CSSProperties;
		input: React.CSSProperties;
		inputHighlights: React.CSSProperties;
	}

	interface TypographyVariantsOptions {
		body3?: React.CSSProperties;
		input?: React.CSSProperties;
		inputHighlights?: React.CSSProperties;
	}

	// interface PaperVariants {

	// }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		body3: true;
		input: true;
		inputHighlights: true;
	}
}
