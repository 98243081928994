import React, { useState } from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { Download } from 'lucide-react';

import { Row } from '@/atoms/row';
import { InvoicesTable } from '@/organisms/invoices-table';
import { OverviewChart } from '@/organisms/overview-chart';
import { trpc } from '@/trpc/client';
import { config } from '@/utils/config';
import { border } from '@/utils/sx';

type Props = {
	from: string;
	to: string;
};

export const Subscriptions = ({ from, to }: Props) => {
	const [search, setSearch] = useState('');

	const overview = trpc.overview.useQuery({ from, to }, { refetchInterval: 5_000 });

	const downloadAll = () => {
		window.open(`${config.API_BASE_PATH}/download/invoices?from=${from}&to=${to}&search=${search}`);
	};

	return (
		<>
			<Typography variant="h2">Subscriptions</Typography>
			<Typography>Filter & deep dive into the subscriptions you&apos;re enrolled to</Typography>

			<Box sx={{ borderRadius: 5, ...border('grey.100'), mt: 5, pt: 5, px: 4 }}>
				<Row sx={{ mb: 4, justifyContent: 'flex-end' }}>
					<Download onClick={downloadAll} />

					<TextField
						placeholder="eg. Slack"
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
						}}
					/>
				</Row>

				{overview.data && <OverviewChart data={overview.data} />}

				<Typography variant="h5" sx={{ mt: 5, mb: 3 }}>
					Invoices
				</Typography>

				<InvoicesTable search={search} from={from} to={to} />
			</Box>
		</>
	);
};
