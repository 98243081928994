import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { BankAccountsManagement } from '@/organisms/bank-accounts-management';
import { links } from '@/paths';

export const OnboardingBankAccounts = () => {
	const navigate = useNavigate();

	return (
		<>
			<Back to={links.onboarding.mailboxes} />

			<Typography variant="h1" sx={{ mt: 3, mb: 1 }}>
				Connect Magnet to your bank account
			</Typography>
			<Typography variant="input">
				It&apos;s an honour to have you here. We hope you&apos;ll happy too!
			</Typography>

			<BankAccountsManagement />

			<Box sx={{ mt: 'auto' }}>
				<Button
					variant="contained"
					startIcon={<ArrowRight />}
					sx={{}}
					onClick={() => {
						navigate(links.onboarding.success);
					}}
				>
					Continue to next step
				</Button>
			</Box>
		</>
	);
};
