import React from 'react';
import { Stack, Typography } from '@mui/material';

import { useAuth } from '@/providers/auth-provider';

export const Profile = () => {
	const { me, user } = useAuth();

	return (
		<>
			<Typography variant="h2" sx={{ mb: 2 }}>
				Profile
			</Typography>

			<Typography>
				ID: {user.uid}
				<br />
				Email: {user.email}
				<br />
				Name: {user.displayName}
				<br />
			</Typography>

			<Typography variant="h3" sx={{ mt: 4 }}>
				Organizations
			</Typography>

			<Stack gap={2}>{me?.organizations.map(({ id, name }) => <Typography key={id}>{name}</Typography>)}</Stack>
		</>
	);
};
