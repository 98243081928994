import React, { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { Link as RouterLink, To, useLocation } from 'react-router-dom';

import { Icon, IconName } from '@/atoms/icon';
import { Link } from '@/atoms/link-component';
import { Logo } from '@/atoms/logo';
import { links } from '@/paths';
import { center } from '@/utils/sx';

import { UserMenu } from './nav.user-menu';

const items: {
	label: ReactNode;
	to: To;
	iconName: IconName;
}[] = [
	{
		label: 'Home',
		to: links.dashboard,
		iconName: 'Home',
	},
	{
		label: 'Subscriptions',
		to: links.subscriptions,
		iconName: 'Bookmark',
	},
	{
		label: 'Invoices',
		to: links.invoices,
		iconName: 'Coins',
	},
];

type Props = {
	navItems?: boolean;
};

export const Nav = ({ navItems = true }: Props) => {
	const { pathname } = useLocation();

	return (
		<Box
			sx={{
				'height': 130,
				'display': 'flex',
				'alignItems': 'center',
				'justifyContent': navItems ? 'flex-start' : 'center',

				'& > a': {
					color: 'white',
					...center,
				},
			}}
		>
			<RouterLink to={links.dashboard}>
				<Logo />
			</RouterLink>

			{navItems && (
				<>
					<Stack direction="row" sx={{ ml: 4.5, gap: 2, mb: 0.5 }}>
						{items.map(({ label, to, iconName }) => {
							const active = pathname === to;

							return (
								<Link
									sx={{
										'color': 'grey.50',
										'display': 'flex',
										'alignItems': 'center',
										'typography': active ? 'inputHighlights' : 'input',
										'&:hover': { typography: 'inputHighlights' },
										'svg': { color: active ? 'primary.300' : 'inherit' },
										'&:hover svg': { color: 'primary.300' },
									}}
									to={to}
									key={iconName}
								>
									<Icon name={iconName} />
									<span>{label}</span>
								</Link>
							);
						})}
					</Stack>

					<UserMenu />
				</>
			)}
		</Box>
	);
};
