import React from 'react';
import { Alert, Skeleton, Typography } from '@mui/material';

import { SimpleTable } from '@/molecules/simple-table';
import { trpc } from '@/trpc/client';

export const Members = () => {
	const { data, isLoading, isError } = trpc.organization.listMembers.useQuery();

	return (
		<>
			<Typography variant="h3" sx={{ mt: 2 }}>
				Members
			</Typography>

			{isError && <Alert severity="error">Failed to load members</Alert>}
			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{data && (
				<SimpleTable
					rows={[['Name', 'Email', ''], ...data.map(({ displayName, email }) => [displayName, email, ''])]}
					firstLineBold
				/>
			)}
		</>
	);
};
