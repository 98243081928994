import React from 'react';
import { Alert, Skeleton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { links } from '@/paths';
import { BackofficeOrganizationBankAccounts } from '@/templates/backoffice/backoffice-organization-bank-accounts';
import { BackofficeOrganizationInvoices } from '@/templates/backoffice/backoffice-organization-invoices';
import { BackofficeOrganizationMailboxes } from '@/templates/backoffice/backoffice-organization-mailboxes';
import { BackofficeOrganizationsMembers } from '@/templates/backoffice/backoffice-organization-users';
import { trpc } from '@/trpc/client';

export const BackofficeOrganizationsDetail = () => {
	const { id } = useParams<{ id: string }>();
	if (!id) throw new Error('No organization id');

	const { data, isError } = trpc.backoffice.organizations.get.useQuery({ id });

	return (
		<>
			<Back to={links.backoffice.organizations.ROOT} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Organization{data ? `: ${data.name}` : ''}
			</Typography>

			{isError && <Alert severity="error">There was an error</Alert>}

			<BackofficeOrganizationBankAccounts organizationId={id} />
			<BackofficeOrganizationMailboxes organizationId={id} />
			<BackofficeOrganizationsMembers organizationId={id} />
			<BackofficeOrganizationInvoices organizationId={id} />
		</>
	);
};
