import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { first } from 'lodash';
import { ArrowRight } from 'lucide-react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { Icon } from '@/atoms/icon';
import { links } from '@/paths';
import { useAuth } from '@/providers/auth-provider';
import { trpc } from '@/trpc/client';

const schema = z.object({
	name: z.string().min(4),
});

type Schema = z.infer<typeof schema>;

export const OnboardingOrganization = () => {
	const navigate = useNavigate();

	const {
		me: { organizations },
	} = useAuth();

	const form = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: { name: first(organizations)?.name ?? '' },
	});

	const utils = trpc.useUtils();
	const { mutate, isLoading } = trpc.organizationCreate.useMutation({
		async onSuccess({ name }) {
			await utils.me.refetch();
			if (name) form.reset({ name });

			navigate(links.onboarding.mailboxes);
		},
	});

	const onSubmit = ({ name }: Schema) => {
		mutate({ name });
	};

	const done = Boolean(organizations.length);

	return (
		<>
			<Typography variant="h1" sx={{ mb: 1 }}>
				Create a space for your company
			</Typography>
			<Typography variant="input">
				It&apos;s an honour to have you here. We hope you&apos;ll happy too!
			</Typography>

			<FormProvider {...form}>
				<Box
					component="form"
					sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
					onSubmit={form.handleSubmit(onSubmit)}
				>
					<Controller
						control={form.control}
						name="name"
						disabled={isLoading || done}
						render={({ field }) => (
							<TextField
								label="Company name"
								fullWidth
								sx={{ mt: 3 }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon name="Building2" />
										</InputAdornment>
									),
								}}
								{...field}
							/>
						)}
					/>

					<Box sx={{ mt: 'auto' }}>
						<Button
							type="submit"
							variant="contained"
							startIcon={<ArrowRight />}
							onClick={(e) => {
								if (done) {
									e.preventDefault();
									navigate(links.onboarding.mailboxes);
								}
							}}
						>
							Continue to next step
						</Button>
					</Box>
				</Box>
			</FormProvider>
		</>
	);
};
