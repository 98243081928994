import React from 'react';
import { Box, Stack } from '@mui/material';

import { RouterOutput } from '../../../../functions/src/trpc/app-router';

type Props = {
	data: RouterOutput['bankAccounts']['list'];
};

export const BankAccountsList = ({ data }: Props) => {
	return (
		<Stack sx={{ my: 2 }}>
			{data?.map(({ institutionName, currency, name }) => {
				return <Box>{[institutionName, currency, name].join()}</Box>;
			})}
		</Stack>
	);
};
