import React, { ReactNode, useState } from 'react';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import { Minus, Plus } from 'lucide-react';

import { border, center } from '@/utils/sx';

type Props = {
	title: ReactNode;
	content: ReactNode;
};

export const CollapsedContent = ({ title, content }: Props) => {
	const [open, setOpen] = useState(false);

	return (
		<Box
			sx={{
				borderRadius: 6,
				p: 1.25,
				bgcolor: 'grey.50',
				...border('grey.100'),
				display: 'grid',
				gridTemplateColumns: '32px 1fr',
				columnGap: 2,
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					width: 32,
					height: 32,
					borderRadius: '50%',
					bgcolor: 'secondary.contrastText',
					color: 'common.white',
					cursor: 'pointer',
					...center,
				}}
				onClick={() => setOpen(!open)}
			>
				{open ? <Minus /> : <Plus />}
			</Box>

			<Typography sx={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
				{title}
			</Typography>

			<Collapse in={open} sx={{ gridColumn: 2 }}>
				<Typography sx={{ mt: 2 }}>{content}</Typography>
			</Collapse>
		</Box>
	);
};
