import React from 'react';
import { Button, Typography } from '@mui/material';

import { Row } from '@/atoms/row';
import { SimpleTable } from '@/molecules/simple-table';
import { trpc } from '@/trpc/client';
import { useConnectMailbox } from '@/utils/mailbox';

export const Mailboxes = () => {
	const { data } = trpc.mailboxesDetailedList.useQuery(undefined, { refetchInterval: 1_000 });

	const { mutate: triggerImport } = trpc.mailboxTriggerImport.useMutation();
	const { mutate: mailboxTriggerInvoiceCreation } = trpc.mailboxTriggerInvoiceCreation.useMutation();
	const { mutate: mailboxTriggerAttachmentsProcess } = trpc.mailboxTriggerAttachmentsProcess.useMutation();

	const { connectMailbox } = useConnectMailbox();

	return (
		<>
			<Typography variant="h3" sx={{ mt: 2 }}>
				Mailboxes
			</Typography>

			<Button onClick={() => connectMailbox()}>Connect new mailbox</Button>

			{data && (
				<SimpleTable
					rows={[
						['Email', 'Messages', 'Attachments', 'Invoices', ''],
						...data.map(({ id, email, messagesCount, attachmentsCount, invoicesCount }) => {
							return [
								email,
								messagesCount,
								attachmentsCount,
								invoicesCount,
								<Row>
									<Button onClick={() => triggerImport({ mailboxId: id })}>Import All</Button>
									<Button onClick={() => mailboxTriggerAttachmentsProcess({ mailboxId: id })}>
										Process Attachments
									</Button>
									<Button onClick={() => mailboxTriggerInvoiceCreation({ mailboxId: id })}>
										Invoices Process
									</Button>
								</Row>,
							];
						}),
					]}
					firstLineBold
				/>
			)}
		</>
	);
};
