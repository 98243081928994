import React from 'react';

export const Google = () => {
	return (
		<svg width="1em" height="1em" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.38 11.2417C22.38 10.4882 22.3079 9.76381 22.174 9.06836H11.5V13.1783H17.5994C17.3367 14.5064 16.5382 15.6317 15.3379 16.3851V19.051H19.0006C21.1437 17.2013 22.38 14.4775 22.38 11.2417Z"
				fill="#4285F4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.499 21.6248C14.559 21.6248 17.1245 20.6734 18.9996 19.0507L15.3369 16.3848C14.322 17.0223 13.0238 17.399 11.499 17.399C8.54717 17.399 6.04868 15.5299 5.15746 13.0186H1.37109V15.7714C3.23595 19.2438 7.06868 21.6248 11.499 21.6248Z"
				fill="#34A853"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.15784 13.0189C4.93117 12.3814 4.80239 11.7004 4.80239 11.0001C4.80239 10.2998 4.93117 9.61886 5.15784 8.98136V6.22852H1.37147C0.603895 7.66289 0.166016 9.28562 0.166016 11.0001C0.166016 12.7146 0.603895 14.3373 1.37147 15.7717L5.15784 13.0189Z"
				fill="#FBBC05"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.499 4.60086C13.1629 4.60086 14.6569 5.13694 15.8314 6.18978L19.082 3.14233C17.1193 1.42784 14.5538 0.375 11.499 0.375C7.06868 0.375 3.23594 2.75597 1.37109 6.22842L5.15746 8.98126C6.04868 6.4699 8.54716 4.60086 11.499 4.60086Z"
				fill="#EA4335"
			/>
		</svg>
	);
};
