import { SxProps } from '@mui/material';

export const border = (color: string): SxProps => ({
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: color,
});

export const center = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};

export const vCenter = {
	display: 'flex',
	alignItems: 'center',
};
