import React from 'react';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { eerie, palette } from '@/utils/theme';

import { RouterOutput } from '../../../functions/src/trpc/app-router';

const textStyle = {
	fontFamily: 'uncut_sans, Arial, Helvetica, sans-serif',
	fontSize: 12,
	stroke: palette.text.primary,
};

type Props = {
	data: RouterOutput['overview'];
};

export const OverviewChart = ({ data }: Props) => {
	let chartData = data.chart.map(({ month, totalAmount }) => {
		return { month, totalAmount: Math.round(totalAmount / 100) };
	});

	return (
		<div>
			<ResponsiveContainer width="100%" height={350}>
				<AreaChart
					width={500}
					height={400}
					data={chartData}
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0,
					}}
				>
					<defs>
						<linearGradient id="areaColor" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor={eerie[400]} stopOpacity={0.8} />
							<stop offset="95%" stopColor={eerie[400]} stopOpacity={0} />
						</linearGradient>
					</defs>
					<CartesianGrid stroke={eerie[300]} vertical={false} />
					<XAxis dataKey="month" strokeOpacity={0} {...textStyle} dy={20} height={50} />
					<Tooltip />
					<YAxis strokeOpacity={0} {...textStyle} width={50} dx={-10} />
					<Area type="monotone" dataKey="totalAmount" stroke={eerie[400]} fill={`url(#areaColor)`} />
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};
