import React from 'react';

export const Logo = () => {
	return (
		<svg width="166" height="27" viewBox="0 0 166 27" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.0289 0.0178134H20.0579C20.0579 3.33111 19.2296 6.46627 17.7956 9.21844C17.6085 9.5747 17.8758 10.0022 18.2766 9.93988C23.2287 9.36094 27.0853 5.15698 27.0853 0.0089067H37.1142C37.1142 3.36673 36.2859 6.51971 34.8163 9.28969C34.6471 9.61924 34.8786 10.0111 35.2527 10.0111H44.1416V20.0401H34.1127V12.1398C34.1127 11.6589 33.5159 11.454 33.222 11.8281C29.5702 16.798 23.7096 20.0312 17.0741 20.0312V12.0953C17.0741 11.6143 16.4507 11.4095 16.1835 11.7836C12.5406 16.8069 6.65331 20.0579 0 20.0579V10.0289C5.53106 10.0289 10.0289 5.54888 10.0289 0V0.0178134Z"
				fill="currentColor"
			/>
			<path
				d="M72.9649 3.47363C70.5957 3.47363 68.752 4.7562 67.5674 6.43956C66.6322 4.44446 64.8865 3.47363 62.7935 3.47363C60.7004 3.47363 58.9903 4.6315 57.9927 6.15455V3.84771H53.2188V20.0668H58.1174V11.2759C58.1174 8.84437 59.3377 7.6865 60.9854 7.6865C62.6331 7.6865 63.3902 9.05814 63.3902 10.7771V20.0668H68.2889V11.2759C68.2889 8.84437 69.5091 7.6865 71.1569 7.6865C72.8046 7.6865 73.526 9.05814 73.526 10.7771V20.0668H78.4247V10.1804C78.4247 5.65578 76.1179 3.47363 72.9649 3.47363Z"
				fill="currentColor"
			/>
			<path
				d="M92.4281 5.65577C91.3682 4.28414 89.8095 3.47363 87.6897 3.47363C83.1028 3.47363 80.6445 7.12538 80.6445 11.9528C80.6445 16.7802 83.1117 20.432 87.6897 20.432C92.2678 20.432 91.5285 19.5235 92.5884 18.0272V20.0579H97.3268V3.84771H92.4281V5.65577ZM89.0257 16.5041C86.5942 16.5041 85.561 14.7228 85.561 11.9528C85.561 9.18283 86.5942 7.40149 89.0257 7.40149C91.4573 7.40149 92.4904 9.18283 92.4904 11.9528C92.4904 14.7228 91.4573 16.5041 89.0257 16.5041Z"
				fill="currentColor"
			/>
			<path
				d="M110.916 5.71812C109.856 4.31086 108.262 3.47363 106.053 3.47363C101.466 3.47363 99.0078 6.96506 99.0078 11.6144C99.0078 16.2637 101.475 19.7195 106.053 19.7195C110.631 19.7195 109.731 18.9357 110.791 17.6264V19.6571C110.791 22.3737 108.983 22.9971 107.327 22.9971C105.67 22.9971 104.485 22.3737 104.334 20.7794H99.5333C99.6847 24.3955 102.339 26.6756 107.113 26.6756C111.887 26.6756 115.69 24.3955 115.69 19.6571V3.84771H110.916V5.71812ZM107.389 15.943C104.957 15.943 103.924 14.2597 103.924 11.6055C103.924 8.95126 104.957 7.24117 107.389 7.24117C109.821 7.24117 110.854 8.92454 110.854 11.6055C110.854 14.2864 109.821 15.943 107.389 15.943Z"
				fill="currentColor"
			/>
			<path
				d="M128.383 3.47363C126.103 3.47363 124.42 4.56916 123.297 6.02986V3.84771H118.523V20.0668H123.422V11.2759C123.422 8.84437 124.918 7.6865 126.575 7.6865C128.232 7.6865 129.256 9.05814 129.256 10.7771V20.0668H134.155V10.1804C134.155 5.65578 131.848 3.47363 128.383 3.47363Z"
				fill="currentColor"
			/>
			<path
				d="M144.652 3.47363C139.317 3.47363 136.324 7.12538 136.324 11.9528C136.324 16.7802 139.317 20.432 144.652 20.432C149.987 20.432 151.67 18.2766 152.73 15.0702H148.054C147.431 16.1924 146.398 16.7892 144.652 16.7892C142.906 16.7892 141.437 15.5422 141.063 13.4224H153.006V12.4872C153.006 6.90271 150.014 3.47363 144.652 3.47363ZM141.098 10.2694C141.535 8.27435 142.782 7.11647 144.652 7.11647C146.522 7.11647 147.805 8.27435 148.241 10.2694H141.098Z"
				fill="currentColor"
			/>
			<path
				d="M161.123 0.0175781H156.224V3.83855H153.508V7.42796H156.224V15.5687C156.224 19.4075 158.567 20.0577 161.248 20.0577H165.051V16.4683H162.584C161.462 16.4683 161.114 16.0318 161.114 15.061V7.41905H165.042V3.82965H161.114V0.0175781H161.123Z"
				fill="currentColor"
			/>
		</svg>
	);
};
