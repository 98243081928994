import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link, To } from 'react-router-dom';

import { border, center } from '@/utils/sx';

import { Icon } from './icon';
import { Row } from './row';

type Props = {
	to: To;
};
export const Back = ({ to }: Props) => {
	return (
		<Link to={to}>
			<Row
				gap={0.5}
				sx={{
					'& > *': {
						color: 'text.primary',
					},
				}}
			>
				<Box
					sx={{
						width: 20,
						height: 20,
						borderRadius: '50%',
						...border('text.disabled'),
						fontSize: 14,
						...center,
					}}
				>
					<Icon name="ChevronLeft" />
				</Box>
				<Typography variant="input">Back</Typography>
			</Row>
		</Link>
	);
};
