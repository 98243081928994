import React, { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { links, paths } from '../paths';
import { useAuth } from '../providers/auth-provider';

type ProtectedRoutesProps = {
	onboardingRequired?: boolean;
};
export const ProtectedRoutes = ({ onboardingRequired = true }: ProtectedRoutesProps) => {
	const { isAuthenticated, me } = useAuth();

	if (!isAuthenticated) {
		return <Navigate to={paths.login} />;
	}

	if (onboardingRequired && !me.organizations.length) {
		return <Navigate to={links.onboarding.ROOT} />;
	}

	return <Outlet />;
};

type PublicOnlyRoutesProps = {};

export const PublicOnlyRoutes = ({}: PublicOnlyRoutesProps) => {
	const { isAuthenticated } = useAuth();

	if (isAuthenticated) {
		return <Navigate to={paths.dashboard} />;
	}

	return <Outlet />;
};
